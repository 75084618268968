import React, { useState, useImperativeHandle, useEffect, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, Token } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const convertMintTime = (utcTimestamp) => {
  const utcTime = new Date(utcTimestamp * 1000);
  const now = new Date();
  const differenceInMs = utcTime - now;
  const differenceInDays = Math.floor(Math.abs(differenceInMs) / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor((Math.abs(differenceInMs) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const differenceInMinutes = Math.floor((Math.abs(differenceInMs) % (1000 * 60 * 60)) / (1000 * 60));
  const differenceFormatted = `${differenceInDays} d, ${String(differenceInHours)} h, ${String(differenceInMinutes)} m`;
  const utcTimeFormatted = utcTime.toUTCString();
  let diffTxt = differenceInMs < 0 ? "ago" : "from now";
  let color = differenceInMs <= 0 || utcTimeFormatted == "Invalid Date" ? "#ff0000" :differenceInMs > 86400000 ? "#ffff55" : "#00ff00";
  const infoTxt = `${utcTimeFormatted} (${differenceFormatted} ${diffTxt})`;
  return { text: infoTxt, color: color };
};

const convertLength = (seconds) => {
  let hours = Math.floor(seconds / 3600);
  let remaining = seconds % 3600;
  let minutes = Math.floor(remaining / 60);
  let result = "";
  if (hours > 0) {
    result += `${hours} h`;
  }
  if (minutes > 0) {
    if (result.length > 0) {
      result += ", ";
    }
    result += `${minutes} m`;
  }
  if (result.length === 0) {
    result = "< 1 m";
  }
  return result;
};

const MintTime = forwardRef(({ onMintTimeSet, deployedAddress, network }, ref) => {
  const [step, setStep] = useState(false);
  const [mintTime, setMintTime] = useState(null);
  const [mintTimeInfo, setMintTimeInfo] = useState(null);
  const [mintLength, setMintLength] = useState(Number(defaults(network).MINT_LENGTH));
  const [mintLengthInfo, setMintLengthInfo] = useState(convertLength(Number(defaults(network).MINT_LENGTH)));
  const [prepLength, setPrepLength] = useState(Number(defaults(network).PREP_LENGTH));
  const [prepLengthInfo, setPrepLengthInfo] = useState(convertLength(Number(defaults(network).PREP_LENGTH)));



  useEffect(() => {
    // Initialize values only once when component mounts
    const initialMintTime = Math.floor(new Date().getTime() / 1000 /3600)*3600 + 7200;
    setMintTime(initialMintTime);
    setMintTimeInfo(convertMintTime(initialMintTime));
  }, []);

  useEffect(() => {
    setMintLength(defaults(network).MINT_LENGTH);
    setPrepLength(defaults(network).PREP_LENGTH);
  }, [network]);
  
  
  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleMintTimeChange = (e) => {
    const utcTimestamp = e.target.value;
    const infoTxt = convertMintTime(utcTimestamp);
    setMintTimeInfo(infoTxt);
    setMintTime(utcTimestamp);
  };

  const handleMintLengthChange = (e) => {
    setMintLength(e.target.value);
    setMintLengthInfo(convertLength(e.target.value));
  };

  const handlePrepLengthChange = (e) => {
    setPrepLength(e.target.value);
    setPrepLengthInfo(convertLength(e.target.value));
  };

  const handleButtonClick = async () => {
    try {
      const result = await Token.setMintTime(deployedAddress, mintTime, mintLength, prepLength,network);
      if (result) {
        onMintTimeSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Set Mint Time</p>

      <p className="DeploymentStep-inputfield-title">Mint Time</p>
      <input type="text" className="DeploymentStep-input" value={mintTime || ""} onChange={handleMintTimeChange} />
      {mintTimeInfo && (
        <p className="DeploymentStep-inputfield-title" style={{ color: mintTimeInfo.color }}>
          {mintTimeInfo.text}
        </p>
      )}
      <div style={{ height: "15px" }}></div>

      <p className="DeploymentStep-inputfield-title">Mint Length [seconds]</p>
      <input type="text" className="DeploymentStep-input" value={mintLength} onChange={handleMintLengthChange} />
      <p className="DeploymentStep-inputfield-title">{mintLengthInfo}</p>
      <div style={{ height: "15px" }}></div>

      <p className="DeploymentStep-inputfield-title">Time between Mint and Fight [seconds]</p>
      <input type="text" className="DeploymentStep-input" value={prepLength} onChange={handlePrepLengthChange} />
      <p className="DeploymentStep-inputfield-title">{prepLengthInfo}</p>
      <div style={{ height: "15px" }}></div>

      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default MintTime;
