import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, Token } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const ContractParamsExtended = forwardRef(({ onContractParamsSet, deployedAddress, network }, ref) => {
  const [step, setStep] = useState(false);

  const [refSharePercent, setRefSharePercent] = useState(5);
  const [teamSharePercent, setTeamSharePercent] = useState(15);
  const [totalMint, setTotalMint] = useState(2500);


  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleRefSharePercentChange = (e) => {
    setRefSharePercent(e.target.value);
  };
  const handleTeamSharePercentChange = (e) => {
    setTeamSharePercent(e.target.value);
  };
  const handleTotalMintChange = (e) => {
    setTotalMint(e.target.value);
  };

  const handleButtonClick = async () => {
    try {
      const result = await Token.setContractParamsExtended(
        deployedAddress,
        refSharePercent,
        teamSharePercent,
        totalMint,
        network
      );
      console.log("Result:", result);
      if (result) {
        onContractParamsSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Set Contract Params Extended</p>

      <p className="DeploymentStep-inputfield-title">Referral Share in Percent</p>
      <input type="text" className="DeploymentStep-input" value={refSharePercent} onChange={handleRefSharePercentChange} />

      <p className="DeploymentStep-inputfield-title">Team Share In Percent</p>
      <input type="text" className="DeploymentStep-input" value={teamSharePercent} onChange={handleTeamSharePercentChange} />

      <p className="DeploymentStep-inputfield-title">Maximum Total Supply</p>
      <input type="text" className="DeploymentStep-input" value={totalMint} onChange={handleTotalMintChange} />


      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default ContractParamsExtended;
