import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const DeploymentStep = forwardRef(({ title, onAddressCreated , network}, ref) => {
  const [name, setName] = useState(defaults(network).NAME);
  const [tag, setTag] = useState(defaults(network).TAG);
  const [description, setDescription] = useState("This is a factory and manager test contract");
  const [coordinatorAddress, setCoordinatorAddress] = useState(defaults(network).VRF_COORDINATOR_ADDRESS);
  const [keyhash, setKeyhash] = useState(defaults(network).KEY_HASH);
  const [subscriptionId, setSubscriptionId] = useState(defaults(network).SUBSCRIPTION_ID);
  const [step, setStep] = useState(false);
  const [vrfType, setVrfType] = useState(defaults(network).VRF_TYPE);
  const [vrfOperator, setVrfOperator] = useState(defaults(network).OPERATOR_ADDRESS || "");

  useImperativeHandle(ref, () => ({
    reset() {
      setName(defaults(network).NAME);
      setTag(defaults(network).TAG);
      setDescription("This is a factory and manager test contract");
      setCoordinatorAddress(defaults(network).VRF_COORDINATOR_ADDRESS);
      setKeyhash(defaults(network).KEY_HASH);
      setSubscriptionId(defaults(network).SUBSCRIPTION_ID);
      setVrfOperator(defaults(network).OPERATOR_ADDRESS || "");
      setVrfType(defaults(network).VRF_TYPE);
      setStep(false);
    },
  }));

  useEffect(() => {
    setName(defaults(network).NAME);
    setTag(defaults(network).TAG);
    setDescription("This is a factory and manager test contract");
    setCoordinatorAddress(defaults(network).VRF_COORDINATOR_ADDRESS);
    setKeyhash(defaults(network).KEY_HASH);
    setSubscriptionId(defaults(network).SUBSCRIPTION_ID);
    setVrfOperator(defaults(network).OPERATOR_ADDRESS || "");
    setVrfType(defaults(network).VRF_TYPE);
  }, [network]); // Runs every time the network value changes

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleTagChange = (e) => {
    setTag(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleCoordinatorChange = (e) => {
    setCoordinatorAddress(e.target.value);
  };
  const handleKeyhashChange = (e) => {
    setKeyhash(e.target.value);
  };
  const handleSubscriptionIdChange = (e) => {
    setSubscriptionId(e.target.value);
  };
  const handleVrfOperatorChange = (e) => {
    setVrfOperator(e.target.value);
  };

  const handleButtonClick = async () => {
    try {
      let address;
      if (vrfType === "CHAINLINK") {
        address = await Factory.createNewGainlingsContractWithAddress(
          name, 
          tag, 
          coordinatorAddress, 
          subscriptionId, 
          keyhash,
          network
        );
      } else {
        address = await Factory.createNewGainlingsContractGelatoWithAddress(
          name,
          tag,
          vrfOperator,
          network
        );
      }
      if (address) {
        setStep(true);
        onAddressCreated(address);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container topContainer">
      <p className="DeploymentStep-title">Deploy Contract</p>
      <p className="DeploymentStep-inputfield-title">Name (The-Gainlings-#)</p>
      <input type="text" className="DeploymentStep-input" value={name} onChange={handleNameChange} />
      <p className="DeploymentStep-inputfield-title">Tag</p>
      <input type="text" className="DeploymentStep-input" value={tag} onChange={handleTagChange} />
      <p className="DeploymentStep-inputfield-title">Description</p>
      <input type="text" className="DeploymentStep-input" value={description} onChange={handleDescriptionChange} />
      
      {vrfType === "CHAINLINK" ? (
        <>
          <p className="DeploymentStep-inputfield-title">VRF Coordinator Address</p>
          <input type="text" className="DeploymentStep-input" value={coordinatorAddress} onChange={handleCoordinatorChange} />
          <p className="DeploymentStep-inputfield-title">VRF Keyhash</p>
          <input type="text" className="DeploymentStep-input" value={keyhash} onChange={handleKeyhashChange} />
          <p className="DeploymentStep-inputfield-title">VRF Subscription ID</p>
          <input type="text" className="DeploymentStep-input" value={subscriptionId} onChange={handleSubscriptionIdChange} />
        </>
      ) : (
        <>
          <p className="DeploymentStep-inputfield-title">VRF Operator</p>
          <input type="text" className="DeploymentStep-input" value={vrfOperator} onChange={handleVrfOperatorChange} />
        </>
      )}

      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default DeploymentStep;
