import * as ethers from "ethers";
import { factoryAbi } from "./factoryAbi.js";
import { gelatoFactoryAbi } from "./gelatoFactoryAbi.js";
import { managerAbi } from "./managerAbi.js";
import { tokenAbi } from "./tokenAbi.js";
import { trophyAbi } from "./trophyAbi.js";
import { vrfCoordinatorAbi } from "./vrfCoordinatorAbi.js";
import { defaults } from "../constants/defaultValues.js";

let contracts = {};

export const connectWallet = async (network) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  contracts.signer = signer;
  console.log("Setup Contracts for : ", network);
  console.log("Factory address: ", defaults(network).FACTORY_CONTRACT_ADDRESS);
  contracts.factory = new ethers.Contract(
    defaults(network).FACTORY_CONTRACT_ADDRESS,
    factoryAbi.abi,
    signer
  );
  contracts.gelatoFactory = new ethers.Contract(
    defaults(network).FACTORY_CONTRACT_ADDRESS,
    gelatoFactoryAbi.abi,
    signer
  );
  contracts.manager = new ethers.Contract(
    defaults(network).MANAGER_CONTRACT_ADDRESS,
    managerAbi.abi,
    signer
  );
  console.log("Factory address: ", contracts.factory.address);

  if ((signer == undefined) | null) {
    return false;
  }
  return true;
};

export const Multicalls = {
  async createNewGainlingsContractAndAddToManager(
    name,
    tag,
    description,
    vrfCoordinator,
    subscriptionId,
    keyHash,
    network
  ) {
    if (!(await connectWallet(network))) return;
    try {
      const deploymentReceipt = await Factory.createNewGainlingsContract(
        name,
        tag,
        vrfCoordinator,
        subscriptionId,
        keyHash,
        network
      );
      console.log(JSON.stringify(deploymentReceipt));

      // Find the OwnershipTransferred event
      const ownershipTransferredEvent = deploymentReceipt.events.find(
        (event) => event.event === "OwnershipTransferred"
      );

      // Extract the contract address from the 'address' field of the event
      const address = ownershipTransferredEvent
        ? ownershipTransferredEvent.address
        : null;

      if (!address) {
        throw new Error(
          "Contract address not found in the transaction receipt"
        );
      }

      const addResult = await Manager.addDeploymentToManager(
        address,
        description
      );
      return addResult;
    } catch (error) {
      console.error(
        "Error in createNewGainlingsContractAndAddToManager:",
        error
      );
      throw error;
    }
  },
};

export const Factory = {
  async createNewGainlingsContract(
    name,
    tag,
    vrfCoordinator,
    subscriptionId,
    keyHash,
    network
  ) {
    if (!(await connectWallet(network))) return;
    try {
      // Format the keyHash if needed
      const formattedKeyHash = keyHash.startsWith("0x")
        ? keyHash
        : `0x${keyHash}`;

      const factoryAddress = contracts.factory.address;
      console.log(
        "Create contract at factory: ",
        factoryAddress,
        "with params: ",
        name,
        tag,
        subscriptionId,
        formattedKeyHash,
        vrfCoordinator
      );
      // Note the corrected parameter order to match the ABI:
      const transaction = await contracts.factory.createContract(
        name, // name
        tag, // symbol
        subscriptionId, // subscriptionId
        formattedKeyHash, // keyHash
        vrfCoordinator // vrfCoordinator address
      );
      const receipt = await transaction.wait();
      return receipt;
    } catch (error) {
      console.error("Error creating new Gainlings contract:", error);
      throw error;
    }
  },
  async createNewGainlingsContractWithAddress(
    name,
    tag,
    vrfCoordinator,
    subscriptionId,
    keyHash,
    network
  ) {
    if (!(await connectWallet(network))) return;
    try {
      const deploymentReceipt = await Factory.createNewGainlingsContract(
        name,
        tag,
        vrfCoordinator,
        subscriptionId,
        keyHash,
        network
      );
      //console.log(JSON.stringify(deploymentReceipt));
      // Find the OwnershipTransferred event
      //const ownershipTransferredEvent = deploymentReceipt.events.find((event) => event.event === "OwnershipTransferred");

      // Extract the contract address from the 'address' field of the event
      // const address = ownershipTransferredEvent ? ownershipTransferredEvent.address : null;
      const address = deploymentReceipt.events[0].address; // ? ownershipTransferredEvent.address : null;

      if (!address) {
        throw new Error(
          "Contract address not found in the transaction receipt"
        );
      }
      return address; // Return the transaction receipt
    } catch (error) {
      console.error("Error creating new Gainlings contract:", error);
      throw error;
    }
  },
  async createNewGainlingsContractGelato(name, tag, operator, network) {
    if (!(await connectWallet(network))) return;
    try {
      const factoryAddress = contracts.gelatoFactory.address;
      console.log(
        "Create contract at factory: ",
        factoryAddress,
        "with params: ",
        name,
        tag,
        operator
      );
      // Note the corrected parameter order to match the ABI:
      const transaction = await contracts.gelatoFactory.createContract(
        name, // name
        tag, // symbol
        operator // operator
      );
      const receipt = await transaction.wait();
      return receipt;
    } catch (error) {
      console.error("Error creating new Gainlings contract:", error);
      throw error;
    }
  },
  async createNewGainlingsContractGelatoWithAddress(
    name,
    tag,
    operator,
    network
  ) {
    if (!(await connectWallet(network))) return;
    try {
      const deploymentReceipt = await Factory.createNewGainlingsContractGelato(
        name,
        tag,
        operator,
        network
      );
      //console.log(JSON.stringify(deploymentReceipt));
      // Find the OwnershipTransferred event
      //const ownershipTransferredEvent = deploymentReceipt.events.find((event) => event.event === "OwnershipTransferred");

      // Extract the contract address from the 'address' field of the event
      // const address = ownershipTransferredEvent ? ownershipTransferredEvent.address : null;
      const address = deploymentReceipt.events[0].address; // ? ownershipTransferredEvent.address : null;

      if (!address) {
        throw new Error(
          "Contract address not found in the transaction receipt"
        );
      }
      return address; // Return the transaction receipt
    } catch (error) {
      console.error("Error creating new Gainlings contract:", error);
      throw error;
    }
  },
};
export const Manager = {
  async getDeploymentInfo(network) {
    if (!(await connectWallet(network))) return;
    try {
      const result = await contracts.manager.Deployments(0);
      console.log(result);
      return result;
    } catch (error) {
      console.error("Error fetching deployment info:", error);
      throw error;
    }
  },
  async getPagedDeployments(page, pagesize, network) {
    if (!(await connectWallet(network))) return;
    try {
      const result = await contracts.manager.getPagedDeployments(
        page,
        pagesize
      );
      return result;
    } catch (error) {
      console.error("Error fetching deployment info:", error);
      throw error;
    }
  },
  async addDeploymentToManager(location, description, network) {
    if (!(await connectWallet(network))) return;
    try {
      const transaction = await contracts.manager.addDeployment(
        location,
        description
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
  async getLatestDeploymentAddress(network) {
    if (!(await connectWallet(network))) return;
    try {
      const result = await contracts.manager.getLatestDeploymentLocation();
      console.log(result);
      return result;
    } catch (error) {
      console.error("Error fetching deployment info:", error);
      throw error;
    }
  },
};
export const Token = {
  async setMerkleProof(contractAddress, merkleProof, network) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        contractAddress,
        tokenAbi.abi,
        contracts.signer
      );
      const transaction = await contract.setMerkleProof(merkleProof);
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
  async setContractParams(
    contractAddress,
    gainlingsStorageContractAddress,
    libraryContractAddress,
    trophyFactoryContractAddress,
    marketContractAddress,
    animationUrl,
    pricePerToken,
    network
  ) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        contractAddress,
        tokenAbi.abi,
        contracts.signer
      );
      const transaction = await contract.setContractParams(
        gainlingsStorageContractAddress,
        libraryContractAddress,
        trophyFactoryContractAddress,
        marketContractAddress,
        animationUrl,
        pricePerToken
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error setContractParams", error);
      throw error;
    }
  },
  async setContractParamsAdvanced(
    contractAddress,
    weightMultiplier,
    weightDivider,
    cooldownBuff,
    mintsPerWallet,
    mintsPerTx,
    network
  ) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        contractAddress,
        tokenAbi.abi,
        contracts.signer
      );
      const transaction = await contract.setContractParamsAdvanced(
        weightMultiplier,
        weightDivider,
        cooldownBuff,
        mintsPerWallet,
        mintsPerTx
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error setContractParamsAdvanced:", error);
      throw error;
    }
  },
  async setContractParamsExtended(
    contractAddress,
    refSharePercent,
    teamSharePercent,
    totalMint,
    network
  ) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        contractAddress,
        tokenAbi.abi,
        contracts.signer
      );
      const transaction = await contract.setContractParamsExtended(
        refSharePercent,
        teamSharePercent,
        totalMint
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error setContractParamsAdvanced:", error);
      throw error;
    }
  },
  async setMintTime(
    contractAddress,
    mintTime,
    mintLenght,
    prepLength,
    network
  ) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        contractAddress,
        tokenAbi.abi,
        contracts.signer
      );
      const transaction = await contract.setMintTime(
        mintTime,
        mintLenght,
        prepLength
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
  async acceptOwnership(contractAddress, network) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        contractAddress,
        tokenAbi.abi,
        contracts.signer
      );
      const transaction = await contract.acceptOwnership();
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error accepting ownership:", error);
      throw error;
    }
  },
  async withdrawFunds(contractAddress, network) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        contractAddress,
        tokenAbi.abi,
        contracts.signer
      );
      const transaction = await contract.withdraw();
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error withdrawing funds:", error);
      throw error;
    }
  },
}; //setAllowedAddress
export const Trophies = {
  async setAllowedAddress(contractAddress, allowed, network) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        defaults(network).TROPHY_CONTRACT_ADDRESS,
        trophyAbi.abi,
        contracts.signer
      );

      const transaction = await contract.setAllowedAddress(
        contractAddress,
        allowed
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
};
export const VRF = {
  async addConsumer(
    vrfCoordinatorAddress,
    subscriptionId,
    consumerAddress,
    network
  ) {
    try {
      if (!(await connectWallet(network))) return;
      const contract = new ethers.Contract(
        vrfCoordinatorAddress,
        vrfCoordinatorAbi.abi,
        contracts.signer
      );
      const transaction = await contract.addConsumer(
        subscriptionId,
        consumerAddress
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding consumer to coordinator:", error);
      throw error;
    }
  },
};
