import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, VRF } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const AddConsumer = forwardRef(({ deployedAddress, onConsumerAdded, network }, ref) => {
  const [step, setStep] = useState(false);
  const [vrfCoordinator, setVrfCoordinator] = useState(defaults(network).VRF_COORDINATOR_ADDRESS);
  const [subscriptionId, setSubscriptionId] = useState(defaults(network).SUBSCRIPTION_ID);

  useEffect(() => {
    setVrfCoordinator(defaults(network).VRF_COORDINATOR_ADDRESS);
    setSubscriptionId(defaults(network).SUBSCRIPTION_ID);
  }, [network]);
  
  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleVrfCoordinatorChange = (e) => {
    setVrfCoordinator(e.target.value);
  };

  const handleButtonClick = async () => {
    try {
      var result = await VRF.addConsumer(vrfCoordinator,subscriptionId,deployedAddress, network);
      if (result.status) {
        onConsumerAdded();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Add Contract to VRF consumers</p>
      <p className="DeploymentStep-inputfield-title">Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={deployedAddress} disabled={true}/>
      <p className="DeploymentStep-inputfield-title">VRF Coordinator Address</p>
      <input type="text" className="DeploymentStep-input" value={vrfCoordinator} onChange={handleVrfCoordinatorChange}/>
      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default AddConsumer;
